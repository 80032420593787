import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'

import { navigate } from '@reach/router'

import { amplifyconfig } from '../utils/config'
Amplify.configure(amplifyconfig)

export function checkLogin() {
  return (dispatch) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log('logged in - user')
        console.log(user)
        // navigate('/support/help')
      })
      .catch(() => {
        console.log('process.env.CONSOLE_DOMAIN')
        console.log(process.env.CONSOLE_DOMAIN)
        // navigate(process.env.CONSOLE_DOMAIN)
      })
  }
}
