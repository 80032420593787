//React
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Actions
import { checkLogin } from '../../actions/submitSupport'

//Packages
import Helmet from 'react-helmet'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

//Components
import Layout from '../../components/layout'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const mapDispatchToProps = (dispatch) => ({
  checkLogin: () => dispatch(checkLogin()),
})

const Snippet = (props) => {
  const { checkLogin } = props

  useEffect(() => {
    checkLogin()
  }, [])

  return (
    <Layout>
      <Helmet
        title="Storylava - Engage visually with rich graphics for selling"
        meta={[
          {
            name: 'description',
            content:
              'Storylava helps your business connect visually with customers to drive action and results',
          },
        ]}
      />

      <div class="gradient-callout-algae">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-left">
              <h1 class="display-2 font-weight-medium white-1">Support</h1>
              <p class="display-4 white-4">Submit a ticket</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default connect(null, mapDispatchToProps)(Snippet)
